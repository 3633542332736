import { API_GET_REALM_CONFIG } from '~/static/urls/general';
import useClearFetchStore from '@/stores/useClearFetchStore';
import { useRuntimeConfig } from '#app';
import { useSession } from '#imports';
import { ref } from 'vue';

/**
 * @description This method is used to get the auth strategy for the selected realm
 * @param {*} realmId
 * @returns auth strategies array
 */
export const useRealm = async (realmId) => {
  const runtimeConfig = useRuntimeConfig();
  const clearObj = useClearFetchStore();
  clearObj.updateLoadingState(true);
  const apiBase = runtimeConfig.public.apiBase;
  const strategy = ref([]);

  // eslint-disable-next-line no-undef
  await $fetch(API_GET_REALM_CONFIG?.replace('{realmId}', realmId), {
    method: 'GET',
    baseURL: apiBase
  })
    .then(async (response) => {
      strategy.value = response.data;
      clearObj.updateLoadingState(false);
      // use session to store the realm config
      const { update } = await useSession();
      await update({ user: undefined });
      return Promise.resolve(strategy);
    })
    .catch(() => {
      strategy.value = [];
      clearObj.updateLoadingState(false);
      return Promise.reject(strategy);
    });

  if (strategy.value?.length > 0) {
    strategy.value = strategy.value?.map((rlm) => {
      rlm.authentication['realmId'] = rlm.id;
      return rlm;
    });
  }
  return Promise.resolve(strategy.value);
};
