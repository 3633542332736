import generateRandomNonce from './generateRandomNonce';

/**
 * Used to prepared the complete auth URL
 * @param {*} key
 * @param {*} strategy
 * @param {*} authCallbackUrl
 * @param {*} origin
 * @returns complete auth URL
 */
export const getStrategyUrl = (key, strategy, authCallbackUrl, origin) => {
  const url = new URL('/authorize', `https://${strategy.domain}`);
  const nonce = generateRandomNonce();
  const customState = { state: strategy.type, realmid: strategy.realmId };
  url.searchParams.append('client_id', strategy.clientId);
  url.searchParams.append('redirect_uri', origin + authCallbackUrl);
  url.searchParams.append('response_type', 'id_token');
  url.searchParams.append('scope', 'openid profile email');
  url.searchParams.append('prompt', 'login');

  // need to change the nonce value - TBD
  url.searchParams.append('nonce', nonce);

  url.searchParams.append('state', btoa(JSON.stringify(customState)));

  if (strategy.organizationId) {
    url.searchParams.append('organization', strategy.organizationId);
  }

  return { url: url.toString(), nonce };
};

// Let's generate a strategy URL that uses response_type as code
export const getStrategyUrlCode = (key, strategy, authCallbackUrl, origin, activationToken = undefined) => {
  const url = new URL('/authorize', `https://${strategy.domain}`);
  const nonce = generateRandomNonce();
  const customState = { state: strategy.type, realmid: strategy.realmId, activationToken };
  url.searchParams.append('client_id', strategy.clientId);
  url.searchParams.append('redirect_uri', origin + authCallbackUrl);
  url.searchParams.append('response_type', 'code');
  url.searchParams.append('scope', 'openid profile email');
  url.searchParams.append('nonce', nonce);
  url.searchParams.append('state', btoa(JSON.stringify(customState)));
  url.searchParams.append('prompt', 'login');
  if (strategy.organizationId) {
    url.searchParams.append('organization', strategy.organizationId);
  }

  return { url: url.toString(), nonce };
};
