<template>
  <div class="text-center w-full" tabindex="0">
    <template v-if="authentication.length && !clearObj.isLoading">
      <template v-for="(strategy, type) in authentication" :key="type">
        <button
          v-if="strategy.authentication.name"
          id="idpAuthentication"
          class="button bg-blue-500 hover:bg-blue-400 text-white font-semibold py-1 rounded focus:outline-none focus:shadow-outline w-full capitalize"
          size="small"
          type="button"
          variant="text"
          @click="handleLogin(type, strategy.authentication, authCallbackUrl, origin)"
        >
          {{ strategy.authentication.name }}
        </button>
      </template>
    </template>

    <button
      v-if="clearObj.isLoading"
      class="button bg-blue-500 hover:bg-blue-400 text-white font-semibold py-1 rounded focus:outline-none focus:shadow-outline w-full capitalize"
      size="small"
      type="button"
      variant="text"
    >
      <ElementsLoadingBox />
    </button>
  </div>
</template>

<script setup>
import { useCookie, useRequestURL, useRuntimeConfig, useSession } from '#imports';
import { onMounted, ref, watchEffect } from 'vue';
import { getStrategyUrlCode } from '~/utils/getStrategyUrl';
import { useRealm } from '~/composables/useRealm';
import { navigateTo } from 'nuxt/app';
import useClearFetchStore from '@/stores/useClearFetchStore';
import { KeyEventCode } from '@/utils/constants';
const clearObj = useClearFetchStore();
const authentication = ref();
const { authCallbackUrl } = useRuntimeConfig().public;
const { origin } = useRequestURL();
const selectedRealmId = useCookie('selectedRealm');

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },

  activationToken: {
    type: String,
    required: false,
    default: ''
  },
  redirectUrl: {
    type: String,
    default: '/',
    required: false
  },
  selectedRealm: {
    type: Number,
    required: true
  }
});

const handleKeyEvent = (event) => {
  if (event.key === KeyEventCode.Enter) {
    document.getElementById('idpAuthentication').click();
  }
};

onMounted(() => {
  document.addEventListener(KeyEventCode.keyDown, handleKeyEvent);
});

const handleLogin = async (type, auth, authCallback, origin1) => {
  const { url, nonce } = getStrategyUrlCode(type, auth, authCallback, origin1, props.activationToken);
  const { update } = await useSession();
  await update({ nonceSession: nonce });
  navigateTo(url, { external: true });
};

onMounted(async () => {
  await update({ user: undefined });
});

watchEffect(async () => {
  if (props.selectedRealm) {
    authentication.value = await useRealm(props.selectedRealm);
    selectedRealmId.value = props.selectedRealm;
  }
});
</script>
